var map;

jQuery(document).ready(function($) {

    $('#carouselHacked').carousel({
        pause: false
    });

    $('#albumnezegeto').carousel({
        interval: false,
        wrap: false
    });

    var $map = document.querySelector('#map');

    if ($map) {
        var lat = 46.078543;
        var long = 18.230548;

        map = new GMaps({
            div: '#map',
            lat: lat,
            lng: long,
            zoom: 15,
            disableDefaultUI: true,
            scrollwheel: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE],
                position: google.maps.ControlPosition.TOP_RIGHT,
                style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP,
                style: google.maps.ZoomControlStyle.SMALL
            }
        });

        map.addMarker({
            lat: lat,
            lng: long,
            icon: "/images/marker.png"
        });
    }

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});

	$('.datepicker').datetimepicker({
		language: 'hu',
		weekStart: 1,
		autoclose: 1,
		todayHighlight: 1,
		startView: 2,
		minView: 2,
		maxView: 4,
		format: 'yyyy-mm-dd'
	});

	$("a.level").each(function(i,o){
		var c = $(o).html().replace('kukac', String.fromCharCode(64));
		c = c.replace(RegExp(/pont/g), String.fromCharCode(46));
		$(o).attr('href', 'mai'+'lt'+'o:'+c).html(c);
	});

    $(document).on('click', '.tovabb-toggle', function() {
        var $row = $(this).closest('.row');
        $row.find('.leiras').slideDown();
        $row.find('.vissza-toggle').show();
        $row.find('.kapcsolat').show();
        $(this).hide();
    });

    $(document).on('click', '.vissza-toggle', function() {
        var $row = $(this).closest('.row');
        $row.find('.leiras').slideUp();
        $row.find('.tovabb-toggle').show();
        $row.find('.kapcsolat').hide();
        $(this).hide();
    });

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    if (window.innerWidth > 767) {
        $('.portfolio-holder').hover(
            function() {
                $(this).find('.album, .kep').addClass('matt');
            },
            function() {
                $(this).find('.album, .kep').removeClass('matt');
            }
        );
    }


    $(".fancy").fancybox({
        maxWidth	: "80%",
        maxHeight	: "80%",
        fitToView	: false,
        openEffect	: 'none',
        closeEffect	: 'none',
        scrolling   : 'no',
        padding     : 0,
        closeClick  : false,
        helpers     : {
            overlay     : {
                closeClick  : false
            }
        },
        tpl         : {
            closeBtn : '<a title="Close" class="fancybox-item fancybox-close" href="javascript:;">' + getSvg('/images/svg/slideshow_bezar.svg') + '</a>',
            prev     : '<a title="Previous" class="fancybox-nav fancybox-prev" href="javascript:;"><span>' + getSvg('/images/svg/slideshow_nyil_bal.svg') + '</span></a>',
            next     : '<a title="Next" class="fancybox-nav fancybox-next" href="javascript:;"><span>' + getSvg('/images/svg/slideshow_nyil_jobb.svg') + '</span></a>'
        }
    });

    $(document).on('click', '.fancy-carousel', function() {
        var sorszam = $(this).attr('data-sorszam');
        var $albumnezegeto = $('#albumnezegeto');
        $albumnezegeto.find('.item').removeClass('active');
        $albumnezegeto.find('.item').eq(sorszam).addClass('active');
        $('.albumnezegeto-container').addClass('visible');
        var $metaElem = $albumnezegeto.find('.item').filter('.active').find('.meta');

        var $infoBlokk = $('.carousel-info-blokk');
        $infoBlokk.find('h2').text($metaElem.attr('data-cim'));
        $infoBlokk.find('.szoveg').html($metaElem.text());
    });

    $('#albumnezegeto').on('slide.bs.carousel', function () {
        var $albumnezegeto = $('#albumnezegeto');
        var $metaElem = $albumnezegeto.find('.item').filter('.active').find('.meta');
        var $infoBlokk = $('.carousel-info-blokk');
        $infoBlokk.find('h2').text($metaElem.attr('data-cim'));
        $infoBlokk.find('.szoveg').html($metaElem.text());
    });

    $(document).on('click', '.carousel-close', function() {
        $('.albumnezegeto-container').removeClass('visible');
    });

    $(document).on('click', '.carousel-info', function() {
        var $albumnezegeto = $('.albumnezegeto');

        if ($(this).is('.open')) {
            console.log('open');
            $(this).removeClass('open');
            $albumnezegeto.find('.carousel-info-blokk').removeClass('col-sm-3 open');
            $albumnezegeto.find('.albumnezegeto-wrap').removeClass('col-sm-9');
        }
        else {
            console.log('close');
            $(this).addClass('open');
            $albumnezegeto.find('.carousel-info-blokk').addClass('col-xs-12 col-sm-3 open');
            $albumnezegeto.find('.albumnezegeto-wrap').addClass('col-xs-12 col-sm-9');
        }
    });

	$(document).on('submit', 'form.recaptcha', function (e) {
		e.preventDefault();
		grecaptcha.execute($(this).find('.g-recaptcha').data('recaptcha-id'));
	});

});

$(window).load(function() {
    var masonryCol = document.querySelector('.masonry-cols');
    if (masonryCol) {
        var m = new Packery(masonryCol, {
            "columnWidth": ".set-grid-size",
            "percentPosition": true,
            itemSelector: '.masonry-elem'
        });
    }

    var introDiv = document.querySelector('#intro-holder.show-intro');
    if (introDiv) {
        var $introDiv = $('#intro-holder');
        setTimeout(
            function() {
                $introDiv.addClass('intro-hidden');
                $introDiv.css('z-index', '0');
            },
            3000
        );
    }
});

function getSvg(url) {
    var svg = null;
    $.ajax({
        url: url,
        success: function(data) {
            svg = data;
        },
        dataType: "html",
        async: false
    });
    return svg;
}
